import React from 'react';
import logo from '../assets/images/CryptoRateLogo.png';
import './nav.css';

const Nav = () => {
  return (
    <nav className="navbar">
      <a className="navbar-brand" href="/">
        <img
          src={logo}
          alt="Crypto Rates"
          width="160"
          height="22.56"
          className="d-inline-block"
        />
      </a>
    </nav>
  );
};

export default Nav;

import React, { useEffect, useState } from 'react';
import './App.css';
import UpdateButton from './UpdateButton.js';

function App() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedCoin, setSelectedCoin] = useState('All');

  useEffect(() => {
    fetch('/data/rates.json')
      .then(response => response.json())
      .then(jsonData => {
        const sortedData = jsonData.sort((a, b) => parseFloat(b.apr) - parseFloat(a.apr));
        setData(sortedData);
        setFilteredData(sortedData);
      });
  }, []);

  const getPlatformLogo = (platform) => {
    switch (platform.toLowerCase()) {
      case 'binance': return '/images/binance.png';
      case 'coinbase': return '/images/coinbase.png';
      case 'okx': return '/images/okx.png';
      case 'aave v3 eth': return '/images/aave.webp';
      case 'ethena': return '/images/ethena.svg';
      case 'lido': return '/images/lido.png';
      case 'compound eth': return '/images/compound.png';

      default: return '/images/cryptorates192.png';
    }
  };

  const getCoinLogo = (coin) => {
    switch (coin.toUpperCase()) {
      case 'BTC': case 'WBTC': return '/images/btc.png';
      case 'USDT': return '/images/usdt.png';
      case 'ETH': return '/images/eth.png';
      case 'USDC': return '/images/usdc.png';
      case 'SUSDE': return '/images/susde.webp';
      default: return '/images/cryptorates192.png';
    }
  };

  const sortByAPR = () => {
    const sortedData = [...filteredData].sort((a, b) => {
      const aprA = parseFloat(a.apr);
      const aprB = parseFloat(b.apr);
      return sortOrder === 'asc' ? aprA - aprB : aprB - aprA;
    });
    setFilteredData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const uniqueCoins = ['All', ...new Set(data.map(item => item.coin))];

  const handleCoinFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedCoin(selected);

    if (selected === 'All') {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter(item => item.coin === selected));
    }
  };

  return (
    <div className="App container-fluid">
  <div className="content">
    <h1>Crypto Rates</h1>
    <h2>Find the best place to grow your crypto wealth!</h2>
    <h3 className="center">
      <UpdateButton />
    </h3>

    <div className="table-container">
      <table className="table-striped">
        <thead>
          <tr>
            <th>Platform</th>
            <th className="hide-on-mobile">Product</th>
            <th className="coin-header">
              Asset
              <select
                className="coin-filter"
                value={selectedCoin}
                onChange={handleCoinFilterChange}
              >
                {uniqueCoins.map((coin, index) => (
                  <option key={index} value={coin}>
                    {coin}
                  </option>
                ))}
              </select>
            </th>
            <th className="apr">
              <div className="apr-container">
                APR
                <button className="sort-button" onClick={sortByAPR}>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className={`sort-icon ${sortOrder === 'asc' ? 'ascending' : 'descending'}`}
                  >
                    <path d={sortOrder === 'asc' ? 'M6 15l6-6 6 6' : 'M6 9l6 6 6-6'} />
                  </svg>
                </button>
              </div>
            </th>
            <th className="hide-on-mobile">Type</th>
            <th>Cooldown</th>
            <th>Update</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>
                <img src={getPlatformLogo(item.platform)} alt={item.platform} width="25" height="25" className="logo-rounded" />
                {item.platform}
              </td>
              <td className="hide-on-mobile">{item.product}</td>
              <td>
                <img src={getCoinLogo(item.coin)} alt={item.coin} width="25" height="25" className="logo-rounded" />
                {item.coin}
              </td>
              <td className="apr">{item.apr}</td>
              <td className="hide-on-mobile">{item.type}</td>
              <td>{item.cooldown}</td>
              <td>{item.ts}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
</div>

    

  );
}

export default App;

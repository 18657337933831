import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import logo from '../assets/images/CryptoRateLogo.png'; 

import React from 'react';
import './footer.css';



const Footer = () => {
  return (
<footer className=" bg-dark  py-2 d-flex flex-column ">
<div className="footer d-flex justify-content-between  ">        {/* Company Logo */}
        <div className="logo-text">
        <img src={logo} alt="Crypto Rates" width="169.9" height="22" className="me-3" />
        <span className="text-light">Made with a full heart</span>
        </div>

        {/* Social Icons */}
        <div>
          <a href="https://x.com/cryptorates_xyz" className="icon-link me-3" target="_blank" rel="noopener noreferrer">
           <i className="fab fa-twitter"></i>
         </a>

          <a href="mailto:info@cryptorates.xyz" className="icon-link me-3">
            <i className="fas fa-envelope"></i>
            </a>
        </div>
      </div>

    </footer>
  );
};

export default Footer;


import React, { useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import './UpdateButton.css';

const UpdateButton = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdateClick = async () => {
    setIsUpdating(true);
    try {
      const response = await fetch('/api/updateData');
      if (response.ok) {
        console.log("Data updated successfully!");
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <button 
      onClick={handleUpdateClick} 
      disabled={isUpdating}
      className={`update-button ${isUpdating ? 'updating' : ''}`}
    >
      {isUpdating ? (
        <>
          <FiRefreshCw className="spin" />
          Updating...
        </>
      ) : (
        <>
          <FiRefreshCw />
          Update Data
        </>
      )}
    </button>
  );
};

export default UpdateButton;
